import React from 'react';
import { gallery } from '../../data/Data'; 
import './totalgallery.css';

const TotalGallery = () => {
  // Video Array
  const videos = [
    { url: './vedios/channel2.mp4' },
    { url: './vedios/closeled.mp4' },
    { url: './vedios/close3.mp4' },
    { url: './vedios/liquid.mp4' },
    { url: './vedios/rgb2.mp4' },
    { url: './vedios/close4.mp4' }
  ];

  return (
    <div>
      <div className="gallery-banner mb-1">
        <h1 className="gallery-heading">Welcome to Our Gallery</h1>
      </div>
      <div className="section1">
        <div>
          <h1 className="heading text-6xl">Gallery</h1>
        </div>
        <div className="gallery-container">
           {gallery.map((service) => (
            <div key={service.id} className="gallery-item">
              <div className="gallery-image-wrapper">
                <img src={service.cover} alt={service.name} className="gallery-image" />
                <div className="gallery-overlay">
                  <h3 className="gallery-overlay-text">{service.category}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="section2">
        <div>
          <h1 className="heading text-6xl">Videos</h1>
        </div>
        <div className="video-container">
          {videos.map((video, index) => (
            <div key={index} className="video-item">
              <video 
                autoPlay 
                muted 
                loop 
                controls 
                className="video-player"
              >
                <source src={video.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TotalGallery;
