import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Services from './components/services/Services';
// import ServiceDetail from './components/services/ServiceDetail';
// import AboutUs from './components/about/AboutUs';
// import Home from './components/home/Home';
import { useState, useEffect } from 'react'; 

import Loader from './components/Loader';
import Navbar from './components/common/navbar/Navbar';
import Pages from './components/pages/Pages';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); 
    }, 2000);

    return () => clearTimeout(timer); // Cleanup
  }, []);
  
  return (
    <>
     {isLoading ? (<Loader/> ):
    
    (<Pages/>)}
    </>
     
    
   
  );
}

export default App;
