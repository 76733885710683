import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../home/Home'

import ServiceDetail from '../services/ServiceDetail'
import Contact from '../contactus/Contact'
import Navbar from '../common/navbar/Navbar'
import Footer from '../common/footer/Footer'
import AboutPage from '../about/AboutPage';
import TotalGallery from '../gallery/TotalGallery';

const Pages = () => {
  return (
   <Router>
    <Navbar/>
    <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path='/about' element={<AboutPage/>}/>
        <Route path="/service/:id" element={<ServiceDetail/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/gallery" element={<TotalGallery/>}/>
    </Routes>
    <Footer/>
   </Router>
  )
}

export default Pages