


export const services = [
    { id: 1, name: "Acrylic Close LED Board",
      info: "The Acrylic Close LED Board is a sleek and durable signage solution designed to enhance brand visibility and create a lasting impression. Made from premium acrylic material, it features enclosed energy-efficient LED lighting that provides bright, even illumination while minimizing energy costs. These boards are fully customizable, allowing businesses to showcase logos, text, or graphics in a modern and professional style. Ideal for shops, restaurants, offices, and events, the weather-resistant design ensures durability for both indoor and outdoor use, making it a versatile choice for effective branding and promotion.",
      img: "./images/close2.jpg" },
    { id: 2, name: "Acrylic Open  LED Board",
         info: "The Acrylic Open LED Board is a stylish signage option with visible LED lights that create a bright, eye-catching effect. Made from durable acrylic, it’s perfect for shops, cafes, bars, and events to display logos or messages in a bold and modern way. These boards are energy-efficient, customizable, and suitable for both indoor and outdoor use, making them a great choice for businesses looking to stand out",
          img: "./images/openled.jpg" },
    { id: 3, name: "CNc Cutting Board", 
        info: "The CNC Cutting Acrylic Board is a precise and customizable signage solution made using advanced CNC (Computer Numerical Control) cutting technology. It allows for intricate designs, logos, and lettering to be crafted from high-quality acrylic material. These boards are durable, lightweight, and have a sleek finish, making them ideal for creating professional and elegant signs for businesses, events, and decor. Perfect for indoor and outdoor use, they add a modern and sophisticated touch to branding and displays.",
         img: "./images/cnc.jpg" },
    { id: 4, name: "Acrylic Channel Letters", 
        info: "Acrylic Channel Letters are 3D, illuminated signs made from durable acrylic material, commonly used for branding and business signage. These letters are designed with a hollow interior to house LED lights, which illuminate the letters and make them stand out, even in low light conditions. Acrylic channel letters are customizable in size, font, and color, allowing businesses to create a unique and professional appearance. They are perfect for storefronts, malls, offices, and other public spaces, offering a modern, eye-catching way to display your brand name or logo.", 
        img: "./images/channel3.jpg" },
    { id: 5, name: "Acrylic Liquid Letters",
         info: "Acrylic Liquid Letters are a type of illuminated signage made from high-quality acrylic, designed with a unique liquid effect that gives the letters a sleek, modern look. These letters are typically backlit with LED lighting, which creates a glowing effect that highlights the fluid, flowing appearance of the acrylic. Acrylic Liquid Letters are highly customizable in terms of shape, color, and size, making them a popular choice for businesses that want to stand out. Ideal for indoor and outdoor signage, these letters provide a sophisticated, stylish, and attention-grabbing display for brands, storefronts, and events.",
          img: "./images/liquid2.jpg" },
    { id: 6, name: "Steel Letters With Lighting", 
        info: "Steel Letters with Lighting are durable, high-quality metal letters that are illuminated with LED lights for a striking and modern look. Made from stainless steel or other metal alloys, these letters are highly resistant to the elements, making them suitable for both indoor and outdoor use. The LED lighting can be installed to shine through or around the letters, creating a glowing effect that enhances visibility and brand presence, even at night. These steel letters offer a premium, industrial aesthetic and are commonly used for corporate signage, store fronts, and building displays, combining strength, style, and functionality.", 
        img: "./images/steel letter.jpg" },
    { id: 7, name: "Gold Letters With Lighting",
         info: "Gold Letters with Lighting are elegant and eye-catching signs made from high-quality materials, coated with a gold finish, and illuminated with LED lights. The gold color gives these letters a luxurious, sophisticated look, while the LED lighting enhances their visibility, especially in low-light conditions. These letters are perfect for businesses or events looking to create a premium, upscale impression. Ideal for use in storefronts, hotels, restaurants, or corporate offices, gold letters with lighting combine a refined aesthetic with practicality, ensuring your brand stands out in style",
          img: "./images/gold.jpg" },
    { id: 8, name: "Marketing & Stationery Solutions", 
        info: "Pamphlets, brochures, and envelope covers are important marketing materials used to promote businesses and services. Pamphlets are compact and informative, ideal for quick overviews, while brochures are more detailed and provide in-depth information about products or events. Envelope covers, often branded with a company’s logo, are used for packaging letters or promotional materials, adding a professional touch to communications. All these items can be customized with your brand’s colors and designs, making them effective tools for marketing and creating a lasting impression on potential customers.", 
        img: "./images/eight.jpg" },
    { id: 9, name: "Aluminium Standees", 
        info: "Aluminium Standees are lightweight, durable, and highly customizable display stands made from aluminum. Often used for advertising and promotional purposes, these stands are perfect for indoor and outdoor events, trade shows, retail spaces, and exhibitions. The aluminum construction ensures strength and stability, while the sleek, modern design adds a professional touch to any display. Aluminum standees can be designed to hold posters, banners, or other marketing materials, offering an easy and portable solution for businesses looking to attract attention and promote their brand effectively.",
         img: "./images/aluminsta.jpg" },
    { id: 10, name: "Glow Sign Boards", 
        info: "Glow Sign Boards are illuminated signage solutions that use LED lights to create a vibrant and eye-catching display. These boards are typically made from materials like acrylic or PVC and are designed to glow brightly, making them perfect for attracting attention, even in low-light conditions. Ideal for businesses, storefronts, restaurants, or events, glow sign boards offer clear visibility and a modern aesthetic. They are energy-efficient, customizable in size and design, and durable enough for both indoor and outdoor use, making them a popular choice for businesses looking to enhance their branding and visibility.",
         img: "./images/glow1.jpg" },
    { id: 11, name: "Acrylic Sandwich Boards",
         info: "Acrylic Sandwich Boards are versatile and stylish display boards commonly used for outdoor signage. They consist of two acrylic panels held together with a frame, often designed in a sandwich-style structure. These boards are lightweight, durable, and weather-resistant, making them ideal for showcasing menus, promotions, or advertisements in restaurants, stores, and events. The acrylic material provides a sleek, modern look, while the design allows for easy customization with printed graphics or messages. Acrylic sandwich boards are easy to move and reposition, making them a practical choice for businesses looking for a functional and attractive signage solution", 
         img: "./images/sandwich.jpg" },
    { id: 12, name: "Vinyl Printing Services",
         info: "Vinyl Printing Services provide high-quality, durable prints on vinyl material, which can be used for a wide range of applications. Vinyl prints are often used for signage, banners, vehicle wraps, wall decals, and window graphics. The material is weather-resistant, making it ideal for both indoor and outdoor use. Vinyl printing allows for vibrant colors, sharp details, and long-lasting results, making it a popular choice for businesses looking to create eye-catching promotional materials. With the ability to customize sizes and designs, vinyl printing offers flexibility and versatility for any branding or marketing need.",
          img: "./images/vinyl2.jpg" },
    { 
      id: 13, 
      name: "Fabric Boards", 
      info: "Fabric Boards are versatile display surfaces covered with fabric, ideal for pinning or tacking documents, posters, and promotional materials. These boards offer a professional look and are commonly used in offices, schools, exhibitions, and events. Fabric boards are available in various colors and textures, providing customization options to suit branding or decor. They are often used for presentations, visual displays, or organizing information, and are easy to maintain and update.",
      img: "./images/flute4.JPG"
      },
      { 
        id: 14, 
        name: "Flute Boards", 
        info: "Flute Boards are lightweight, corrugated boards with a fluted structure between two flat layers of paper, making them strong and durable. Used primarily for signage, displays, and packaging, they are perfect for advertising in retail, trade shows, and public spaces. Flute boards are easy to print on, providing a smooth surface for vibrant graphics. They are also eco-friendly and recyclable, making them an environmentally conscious choice for businesses seeking affordable and effective signage solutions.",
        img: "./images/fabric.jpg"
      },
      { 
        id: 15, 
        name: "FrontlineFlex Boards", 
        info: "FrontlineFlex Boards are flexible, high-quality display boards that combine strength and versatility. Made from materials like PVC or synthetic fabric, these boards can be easily shaped, printed on, and displayed in various ways. FrontlineFlex boards are ideal for both indoor and outdoor advertising, providing a durable solution for businesses, events, and exhibitions. They offer vibrant color reproduction and are lightweight, making them easy to transport and set up. Their flexible design allows for a wide range of customizations in size and shape.",
        img: "./images/frontlineflex.jpg"
      }
          
  ];
  export const sliderImages = [
    { src: "../images/liquid2.jpg", alt: "Image 1", text: "This is image 1", className: "image1" },
    { src: "../images/close2.jpg", alt: "Image 2", text: "This is image 2", className: "image2" },
    { src: "../images/glow1.jpg", alt: "Image 3", text: "This is image 3", className: "image3" },
    { src: "../images/openled.jpg", alt: "Image 4", text: "This is image 4", className: "image4" },
    { src: "../images/sandwich.jpg", alt: "Image 5", text: "This is image 5", className: "image5" },
    { src: "../images/vinyl2.jpg", alt: "Image 5", text: "This is image 5", className: "image6" },
    { src: "../images/gold.jpg", alt: "Image 5", text: "This is image 5", className: "image5" },
    { src: "../images/cnc.jpg", alt: "Image 5", text: "This is image 5", className: "image5" },
    { src: "../images/channel3.jpg", alt: "Image 5", text: "This is image 5", className: "image5" },
    { src: "../images/ten.jpeg", alt: "Image 5", text: "This is image 5", className: "image5" },
  ];
  export const serviceData = [
    {
      id: 1,
      title: "Acrylic Close LED Boards",
      description: [
       " Acrylic closed boards are a modern and functional solution for communication and display needs, blending practicality with aesthetics. Made from premium-grade acrylic, these boards offer excellent clarity and a smooth, non-porous surface that is ideal for both dry-erase markers and permanent displays. The closed design often features a protective, transparent cover—either hinged or sliding—ensuring that the content inside is safeguarded against dust, moisture, scratches, and accidental damage.",

        "These boards are commonly used in environments where information needs to remain visible but secure, such as in offices for announcements, schools for notices, hospitals for patient information, and retail spaces for product promotions or menus. The lightweight yet sturdy construction of acrylic makes these boards easy to mount on walls or other surfaces, and their sleek, modern design complements contemporary interiors.",
      ],
  
      sidebarLinks: [
        "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
      ],
      videoUrl: "/vedios/closeled.mp4",
    },
    // Add other services
    {
        id: 2,
        title: "Acrylic Open LED Board",
        description: [   "An acrylic open LED board is a modern, eye-catching signage solution widely used for advertising and decoration. Crafted from durable and transparent acrylic sheets, these boards feature LED lights embedded in or behind the material to create vibrant, illuminated designs, text, or logos.",
                      "The use of LEDs ensures energy efficiency, long-lasting brightness, and the ability to customize colors and effects to suit various needs. Acrylic open LED boards are commonly found in retail stores, restaurants, offices, and events, as they offer a sleek, professional look that captures attention both day and night. Their lightweight and weather-resistant properties make them suitable for both indoor and outdoor applications.",
     
        ],
        imageUrl: "/images/openled.jpg", alt: "Channel Letter Image 1" ,
    
        
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ]
      },
      {
        id: 3,
        title: "CNC Cutting Acrylic Boards",
        description: [
    
        
"CNC (Computer Numerical Control) cutting is a method used to cut or engrave acrylic boards with high precision. A machine controlled by a computer follows a design to make exact cuts, ensuring smooth edges and consistent quality. This method is great for creating signs, display stands, decorations, and architectural features.",
"Acrylic boards are ideal for CNC cutting because they are strong, versatile, and can be shaped easily without breaking. The process allows for custom designs, making it perfect for businesses and individuals who want to create unique and professional products. CNC cutting provides clean finishes and can handle various design needs, making it popular in fields like advertising, interior design, and manufacturing.",

     
        ],
        imageUrl: "/images/cnc2.jpg", alt: "Channel Letter Image 1",
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ]
      },
      {
        id: 4,
        title: "Acrylic Channel Letters",
        description: [
              
    "Acrylic channel letters are a type of three-dimensional signage commonly used for storefronts, business logos,and advertising. These letters are crafted from acrylic material and typically have a hollowed-out back or channel that allows for internal lighting, often LED lights.",
    "The combination of acrylic's transparency and vibrant lighting effects makes acrylic channel letters visually striking, especially at night or in low-light environments. They can be customized in various sizes, colors, and fonts to fit the branding needs of any business. These letters are known for their durability, versatility,and ability to attract attention, making them a popular choice for businesses looking to stand out.",
     
        ],
        imageUrl:"/images/channel3.jpg", alt: "Channel Letter Image 1",
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ],
        videoUrl: "/vedios/channel.mp4",

      },
      {
        id: 5,
        title: "Acrylic Liquid Letters ",
        description: [
          "Acrylic liquid letters are custom-crafted, three-dimensional letters made from high-quality acrylic material and coated with a glossy,",
           "liquid-like substance that gives them a sleek, reflective finish. These letters are highly customizable in terms of color and size, making them perfect for signage, branding, and interior decor. Their durable, weather-resistant properties make them ideal for both indoor and outdoor use, while the glossy finish and potential for LED backlighting ensure they stand out and",
            "create a luxurious, modern effect in any setting. Whether for advertising, storefronts, or events,",
           " acrylic liquid letters add a premium, eye-catching touch to any design.",
        ],
       
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures/Envelopes",
        ],
        videoUrl: "/vedios/liquid.mp4",

      },
      {
        id: 6,
        title: "Aluminum Standees",
        description: [
          "Aluminum standees are sleek, durable, and versatile display structures widely used for advertising, promotions, and informational purposes.",
          "Made from high-quality aluminum, these standees are lightweight yet sturdy,making them easy to transport and set up in various locations.Their corrosion-resistant properties ensure they can withstand indoor and outdoor conditions,maintaining a polished, professional appearance over time.",
          "Aluminum standees often feature customizable panels for displaying graphics,text, or branding elements, making them ideal for events, exhibitions, retail stores,and corporate offices. Their modern aesthetic and clean finish make them a popular choice for businesses looking to leave a lasting impression.",
          "Additionally, they come in various sizes and styles,from simple poster frames to intricate, multi-layered designs, catering to a wide range of display needs.",
            
 ],
        imageUrl:"/images/aluminsta.jpg", alt: "Channel Letter Image 1",
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ]
      },
      
      {
        id: 7,
        title: "Glow sign boards ",
        description: [
          
          "Glow sign boards are illuminated signage solutions used for advertising and branding, especially in low-light or nighttime settings.",
           "These boards typically feature vibrant graphics lit by LED or fluorescent lights, ensuring visibility from a distance.",
            "Durable and weather-resistant, glow sign boards are ideal for shops, restaurants,",
           "and commercial spaces, offering an eye-catching and professional appearance that attracts customers effectively.",
            

        ],
        imageUrl : "/images/glow1.jpg", alt: "Channel Letter Image 1",
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ]
      },
      {
        id: 8,
        title: "Flute Boards",
        description: [
          "Flute board is a type of corrugated board commonly used in signage, displays, and packaging. It consists of a fluted, wave-like structure sandwiched between two flat layers of paper, giving it strength and durability while keeping it lightweight.",
           "Flute boards are often used for outdoor and indoor signs, point-of-sale displays, and construction of promotional materials. They are easy to print on, making them ideal for advertising, and are also environmentally friendly, being recyclable.",
            

        ],
        imageUrl:"/images/flute4.JPG", alt: "Channel Letter Image 1",
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ]
      },
      {
        id: 9,
        title: "Fabric Board",
        description: [
               
          "A fabric board is a type of display or bulletin board covered with fabric, often used for presentations, signage, or organizing information. The fabric covering provides a professional appearance and can be easily pinned or tacked with documents, posters, or other materials. Fabric boards are commonly used in offices, schools, trade shows, and events for visual displays or as part of interior decor.",
           "They are available in a variety of colors and textures, allowing for customization to match a brand or aesthetic.",],
        imageUrl:  "/images/fabric.jpg", alt: "Channel Letter Image 1", 
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ]
      },
      {
        id: 10,
        title: "Acrylic Sandwich Boards",
        description: [
               
          
        "Acrylic sandwich boards are a type of signage made by sandwiching a printed graphic or message between two layers of clear or colored acrylic panels. These boards are often used for displaying promotional content, directions, or information in a sleek and modern way. The acrylic panels offer durability, weather resistance, and a polished appearance, making them suitable for both indoor and outdoor use.",
        "Acrylic sandwich boards are commonly used in retail stores, events, exhibitions, and restaurants to attract attention and convey messages clearly.",

            

        ],
        imageUrl: "/images/sandwich.jpg", alt: "Channel Letter Image 1" ,
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ]
      },
      {
        id: 11,
        title: "Business Cards",
        description: [
          "Business cards are compact, professional tools used to share contact information and create a lasting impression in personal and business interactions. Typically measuring 3.5 x 2 inches, they include essential details like a name, job title, company name, phone number, email, and sometimes a website or social media links.",

          "Business cards often feature custom designs, including logos and brand colors, to represent the identity of an individual or organization. Used in networking, meetings, or events, they help establish credibility, foster connections, and ensure easy follow-up. Despite the rise of digital communication, business cards remain a timeless and effective networking tool.",


            

        ],
        imageUrl: "/images/visiting.jpg", alt: "Channel Letter Image 1",
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ]
      },
      {
        id: 12,
        title: "Pamphlets/Brochures",
        description: [
               
          "Pamphlets: Pamphlets are small, folded brochures used to provide quick, informative content about a product, service, or event.",

          "Brochures: Brochures are multi-page printed materials that give detailed information, often with visuals, about a business, service, or event.",
          
          "Envelopes: Envelopes are protective covers made from paper or cardstock, used to hold and send letters, documents, or promotional materials.",
            

        ],
        imageUrl: "/images/eight.jpg", alt: "Channel Letter Image 1" ,
  
        sidebarLinks: [
          "Acrylic Close LED Board",
        "Acrylic Open LED Board",
        "CNc Cutting Acrylic Board",
        "Acrylic Channel Letters",
        "Acrylic Liquid Letters",
        "Aluminium Standees",
       "Glow Sign Boards",
       "Flute Board",
       "Fabric Board",
       "Acrylic Sandwich Boards",
       "Business Cards",
       "Pamphlets/Brochures",
        ]
      },
  ];
  export const gallery=[
    {
      id: 1,
      cover: "../images/close2.jpg",
      category: "Close LED Board",
   
      
    },
    {
      id: 2,
      cover: "../images/openled.jpg",
      category: "Open LED Board",
   
      
    },
    {
      id: 3,
      cover: "../images/cnc.jpg",
      category: "CNC Creations",
   
      
    },
    {
      id: 4,
      cover: "../images/channel3.jpg",
      category: "Channel Letters",
   
      
    },
    {
      id: 5,
      cover: "../images/liquid2.jpg",
      category: "Liquid Letters",
   
      
    },
    {
      id: 6,
      cover: "../images/steel letter.jpg",
      category: "SteelFlash Prints",
   
      
    },
    {
      id: 7,
      cover: "../images/gold.jpg",
      category: "Golden Glow Letters",
   
      
    },
    {
      id: 8,
      cover: "../images/eight.jpg",
      category: "Broucher",
   
      
    },
    {
      id: 9,
      cover: "../images/aluminsta.jpg",
      category: "Aluminum Standees",
   
      
    },
    {
      id: 10,
      cover: "../images/glow1.jpg",
      category: "Glow Sign Board",
   
      
    },
    {
      id: 11,
      cover: "../images/sandwich.jpg",
      category: "Sandwich Board",
   
      
    },
    {
      id: 12,
      cover: "../images/vinyl3.jpg",
      category: "Vinyl Printing ",
   
      
    },
    {
      id: 13,
      cover: "../images/fabric6.JPG",
      category: "Fabric Boards",
   
      
    },

    {
      id: 14,
      cover: "../images/flute4.JPG",
      category: "Vinyl Printing ",
   
      
    },

    {
      id: 15,
      cover: "../images/frontlineflex.jpg",
      category: "FrontLineFlex ",
   
      
    },
    {
      id: 16,
      cover: "../images/fabric3.jpg",
      category: "Fabric Boards",
   
      
    },
    {
      id: 17,
      cover: "../images/fabric2.jpg",
      category: "Fabric Boards",
   
      
    },
    {
      id: 18,
      cover: "../images/flute2.jpg",
      category: "FluteBoards",
   
      
    },
    {
      id: 19,
      cover: "../images/close3.jpg",
      category: "CloseLED",
   
      
    },

    {
      id: 20,
      cover: "../images/vinyl2.jpg",
      category: "VinylPrinting",
   
      
    },
   




  ]
  export const nav = [
    {
      text: "home",
      path: "/",
    },
    {
      text: "about",
      path: "/about",
    },
    {
      text: "services",
      path: "/services",
    },
    {
        text: "gallery",
        path: "/gallery",
      },
  
    {
      text: "contact",
      path: "/contact",
    },
  ]

 

