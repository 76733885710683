import React from 'react';
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container-fluid bg-black">
      <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-3 my-0 border-top">
        <div className="col mb-3">
          {/* Logo */}
          <a
            href="/"
            className="d-flex flex-column align-items-center mb-3 link-body-emphasis text-decoration-none"
          >
            <img
              className="navbar-logo"
              src="./premdigitallogo.png"
              style={{ height: "90px" }}
              alt="Logo"
            />
            <span className="fs-5 text-light" style={{ color: "#F97316" }}>
              Prem Digitals
            </span>
          </a>
          <p className="text-light mb-0 ml-12">
            Transforming spaces with vibrant signboards that communicate your message effortlessly.
          </p>
        </div>

        <div className="col mb-3 mt-5 " >
          {/* Address */}
          <p className="text-light mb-0"> <span style={{ color: "#F97316" }}>Phone :</span> +91 9700277740</p>
          <p className="whitespace-nowrap text-light">
            <span className="text-orange-500">Email:</span>contactus@premdigitalsignage.com
          </p>
          <p className="text-light mb-0">1-112/3/5/A, 1st floor ,Miyapur Rd,</p>
          <p className="text-light mb-0">Hyderabad, Telangana 500049</p>
        </div>

        <div className="col mb-3">
          <h5 style={{ color: '#F97316' }}>Important Links</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Home
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Features
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Pricing
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                FAQs
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                About
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="col mb-3">
          <h5 style={{ color: '#F97316' }}>Areas We Serve</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Miyapur
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Kukatpally
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Madhapur
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                KPHB
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Ameerpet
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="col mb-3 mr-0">
          <h5 style={{ color: '#F97316' }}>Our Services</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Acrylic Boards
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Glow Sign Boards
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Channel Letters
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
              CNc cutting Board
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink href="#" className="nav-link p-0 text-light">
                Business Cards
              </NavLink>
            </li>
          </ul>
        </div>
      </footer>

      <footer className="row mt-0">
        <div>
          <p className="text-light row justify-content-center d-flex">© 2025 Copyright : PremDigital | Powered by BOBROS</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
