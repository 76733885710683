import React,{useEffect,useState} from 'react'
import "./loader.css"
const Loader = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
  
      return () => clearTimeout(timer); 
    }, []);
  
  return (<div className="flex items-center justify-center min-h-screen bg-gray-800">
   
      <div className="text-center">
        <img
          src="./premdigitallogo.png"
          alt="Logo"
          className="mx-auto mb-4 animate-slideLeft"
          style={{ width: '190px', height: '190px' }}
        />
        <div className="text-white text-4xl font-bold animate-text-fade-in">
       PremDigital
        </div>
      </div>
   
  </div>
  )
}

export default Loader