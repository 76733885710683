import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom'; 
import { serviceData } from '../../data/Data'; 
import Slider from '../imageslider/Slider';
import "./services.css";

const ServiceDetail = () => {
  const { id } = useParams(); 
  const navigate = useNavigate(); 


  const service = serviceData.find((service) => service.id === parseInt(id)); 

  if (!service) {
    return <div>Service not found!</div>;
  }

  const handleSidebarClick = (serviceId) => {
    navigate(`/service/${serviceId}`); 
  };

  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      <div className="mb-4">
        <Slider images={service.images} />
      </div>
      <h1 className="text-3xl font-bold text-orange-500 text-center py-4">
        {service.title.toUpperCase()}
      </h1>

      <div className="flex flex-grow sidebar">
        {/* Sidebar */}
        <div className="w-1/4 h-1/3 bg-gray-900 flex flex-col ml-36">
          <div className="p-4 bg-orange-500 text-black font-bold">
            {service.title.toUpperCase()}
          </div>
          <div className="flex flex-col gap-2 p-2">
            {service.sidebarLinks?.map((link, index) => (
              <Link
                key={index}
                to={`/service/${index + 1}`}
                className="text-left bg-gray-800 hover:bg-orange-500 px-4 py-2 rounded"
                onClick={() => handleSidebarClick(service.id)} 
              >
                {link}
              </Link>
            ))}
          </div>
        </div>

 
        <div className="w-3/4 p-8">
          {service.description?.map((paragraph, index) => (
            <p key={index} className="mb-4">
              {paragraph}
            </p>
          ))}

        
          <div className="media-container mt-6">
            {service.videoUrl ? (
              // If video URL exists, render the video player
              <video 
                autoPlay 
                muted 
                loop 
                controls 
                className="w-full rounded-lg border-4 border-orange-500 shadow-lg"
              >
                <source src={service.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              // If no video, render an image (assuming there's an imageUrl)
              <img 
                src={service.imageUrl || 'default-image.jpg'} 
                alt={service.title} 
                className="w-[500px] h-[400px] rounded-lg border-4 border-orange-500 shadow-lg"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
