import React from 'react'
import Services from '../services/Services'
import AboutUs from '../about/AboutUs'
import Gallery from '../gallery/Gallery'
import Test from '../Test'
import WhyUs from '../services/WhyUs'
import Navbar from '../common/navbar/Navbar'
import Footer from '../common/footer/Footer'



const Home = () => {
  return (
    <>
    
    <Test/>
    <div class="border-t-2 border-orange-500"/>
    <Services/>
     <div class="border-t-2 border-orange-500"/>
     <AboutUs/>
     <div class="border-t-2 border-orange-500"/>

     <Gallery/>
     <WhyUs/>
      <section className="container mt-4">
                <div className="row">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 map-container">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.2110528595585!2d78.35898007521284!3d17.49743578340866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb935b2a580a6b%3A0x1ccfea712e7ace6!2sPrem%20digitals%20-%20Best%20Ads%20LED%20signage%20boards%2C%20Flex%20printing%2C%20Liquid%2C%20Acrylic%2C%203D%2C%20In%20%26%20Outdoor%20ACP%20Cladding%20Manufacturers%20Hyd!5e0!3m2!1sen!2sin!4v1735385648769!5m2!1sen!2sin"
                                title="Prem Digitals Location"
                                loading="lazy"
                                allowFullScreen=""
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>
  
     
     
    </>
  
  )
}


export default Home