import React from 'react'

const AboutUs = () => {
  return (
          <div className="flex flex-col md:flex-row items-center bg-black   bg-opacity-90 p-6 md:p-12">
          {/* Content Section */}
          <div className="md:w-1/2 text-center md:text-left space-y-4 mb-7">
            <h2 className="text-4xl font-bold text-orange-500 mb-10 font-serif">
              About Us
            </h2>
            <p className="text-lg text-white font-sans leading-relaxed">
            Welcome to <b className='text-orange-500'>Prem Digitals</b>, your trusted partner for premium signage and printing solutions in Hyderabad. 
            Based in Miyapur, we take pride in being a top-rated service provider with a stellar 5-star reputation.
            </p>
            <p className="text-lg text-white font-sans leading-relaxed">
            We specialize in creating high-quality <b className='text-orange-500'>Acrylic Boards, CNC-cut designs, Vinyl Printing, Channel Letters, Glow Boards,</b> and much more.
             Our services extend beyond signboards to include visiting cards, pamphlets, and brochures, 
             making us a one-stop solution for all your branding and promotional needs.
            </p>
            <p className="text-lg text-white font-sans leading-relaxed">
            We cater to a wide range of establishments, including offices, hotels, hospitals,
             and other businesses, ensuring that your brand stands out with innovative designs and excellent craftsmanship.
            </p>
            <p className="text-lg text-white font-sans leading-relaxed">
            At Prem Digital, our commitment is to deliver exceptional quality, creative solutions, and reliable service tailored to your requirements.
             Whether you need eye-catching signage or professional printed materials, we’re here to help your business leave a lasting impression.
            
             Let Prem Digital bring your vision to life with our comprehensive range of services!
            </p>
           
            <p className="text-lg text-white font-sans leading-relaxed">
       
            
             Let Prem Digital bring your vision to life with our comprehensive range of services!
            </p>
          </div>
    
          {/* Image Section */}
          <div className="lg:w-1/2 mt-6 lg:mt-0 flex  ml-20 justify-center">
        <img
          src="./about.png"
          alt="About PremDigital"
          className="rounded-lg shadow-lg max-w-full sm:ml-3"
        />
      
      </div>
      
        </div>
        
        
      );
  
}

export default AboutUs