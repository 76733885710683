import React from 'react';

import './contact.css'

const Contact = () => {
    return (
        <div>
            {/* Banner Section */}
      <div className="gallery-banner mb-7">
        <h1 className="gallery-heading">Contact Us</h1>
      </div>
        <div className='bg-black opacity-90'>
            <div className="container">
                <div className="row g-4">
                
                    <div className="col-md-4 col-xl-4 ">
                        <div className="card shadow-lg bg-dark border-0 h-100">
                            <div className="card-body d-flex flex-column">
                                <h6 className="mb-3  fw-bold display-6 " style={{ color: '#F97316' }}> Our Location</h6>
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-map-marker-alt text-primary fs-2 me-3"></i>
                                    <p className="mb-0 text-light " style={{ color: '#F97316' }}>
                                        1-112/3/5/A, 1st floor, Balaji Nagar, Kalapuram Basti, Miyapur Cross (X) Road Signal, Bollaram Road, and Miyapur Rd, Hyderabad, Telangana 500049
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-xl-4">
                        <div className="card shadow-lg bg-dark border-0 h-100">
                            <div className="card-body d-flex flex-column">
                                <h6 className="mb-3 fw-bold display-6 " style={{ color: '#F97316' }}>Phone Number</h6>
                                <div className="d-flex align-items-start">
                                    <i className="fa fa-phone-alt text-primary fs-2 me-3"></i>
                                    <div>
                                        <h5 className="mb-2 text-light" style={{ color: '#F97316' }}>+91 9700277740</h5>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-xl-4">
                        <div className="card shadow-lg bg-dark border-0 h-100">
                            <div className="card-body d-flex flex-column">
                            <h6 
                                className="mb-3 fw-bold display-6" 
                                style={{ color: '#F97316' }}
                            >
                                Email Address
                            </h6>
                            <div className="d-flex align-items-center">
                                <i className="fa fa-envelope text-primary fs-2 me-3"></i>
                                <div className="w-full overflow-hidden break-words">
                                <h6 
                                    className="mb-2 text-light text-sm md:text-base break-all" 
                                    style={{ color: '#F97316', wordBreak: 'break-word' }}
                                >
                                    contactus@premdigitalsignage.com
                                </h6>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
            </div>
            <div className="row justify-content-center mt-5 p-5 text-center">
                <h1 className="fw-bold display-5 " style={{ color: '#F97316' }}>We Provide Best Services</h1>
                <h3 className="fw-medium display-6 text-light" style={{ color: '#F97316' }}>Reach Us</h3>
            </div>
            {/* Map Section */}
            <section className="container mt-4">
                <div className="row">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 map-container">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.2110528595585!2d78.35898007521284!3d17.49743578340866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb935b2a580a6b%3A0x1ccfea712e7ace6!2sPrem%20digitals%20-%20Best%20Ads%20LED%20signage%20boards%2C%20Flex%20printing%2C%20Liquid%2C%20Acrylic%2C%203D%2C%20In%20%26%20Outdoor%20ACP%20Cladding%20Manufacturers%20Hyd!5e0!3m2!1sen!2sin!4v1735385648769!5m2!1sen!2sin"
                                title="Prem Digitals Location"
                                loading="lazy"
                                allowFullScreen=""
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>

        </div>
        </div>
    );
};


export default Contact;
