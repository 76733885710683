import React from 'react';
import './about.css';
import WhyUs from '../services/WhyUs';

const AboutPage = () => {
  return (
    <div>
      <div className="gallery-banner">
        <h1 className="gallery-heading">About Us</h1>
      </div>
    <div className="bg-black">
  

      <section>
        <div className="px-4 py-5 my-1 text-center">
          <h1 className="text-5xl font-extrabold text-orange-500 mb-4">We Have More Than 20+ Years of Experience</h1>
          <div className="lg:w-3/4 mx-auto">
            <p className="text-lg mb-4 text-gray-300">
              Prem Digitals, a leading manufacturer of LED and Flex Boards in Miyapur, Hyderabad, has been successfully catering to customer demands since its inception in 2017. Known for its exceptional quality and service, the business has built a strong reputation in the field.
            </p>
            <p className="text-lg mb-4 text-gray-300">
              At PremDigitals, we pride ourselves on being a one-stop solution for all your signboard and branding needs. Our mission is to help businesses and individuals stand out with visually stunning and innovative designs that not only grab attention but also leave a lasting impression.
            </p>
            <p className="text-lg mb-4 text-gray-300">
              Flex printing involves premium digital banner printing using high-quality industrial presses. We also offer alternatives such as star flex, which provides a shiny, reflective surface for enhanced visual appeal.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-10">
          <div className="text-center mb-8">
            <h2 className="text-5xl font-extrabold text-orange-500">Customer Says</h2>
          </div>

          <div className="grid md:grid-cols-3 gap-4">
            {/** First Card */}
            <div className="card bg-gray-900 p-6 text-center">
              <h5 className="text-lg font-semibold text-gray-300">Sankar Kumar</h5>
              <p className="text-gray-400">
                Nice quality acrylic gold colour ss letters with LED sign board's, good quality good response, in time delivery, best shop in Hyderabad
              </p>
              <div className="text-orange-500 flex justify-center space-x-1 mt-2">
                {[...Array(4)].map((_, i) => (
                  <i key={i} className="bi bi-star-fill"></i>
                ))}
              </div>
            </div>

            {/** Second Card */}
            <div className="card bg-gray-800 p-6 text-center">
              <h5 className="text-lg font-semibold text-orange-500">Pandu Pandu</h5>
              <p className="text-white">
                Print quality (color accuracy, text sharpness), paper handling capabilities, connectivity options, ink costs, noise level, duty cycle, and all the printed flex are good 👍👍
              </p>
              <div className="text-orange-500 flex justify-center space-x-1 mt-2">
                {[...Array(4)].map((_, i) => (
                  <i key={i} className="bi bi-star-fill"></i>
                ))}
              </div>
            </div>

            {/** Third Card */}
            <div className="card bg-gray-900 p-6 text-center">
              <h5 className="text-lg font-semibold text-gray-300">Vamsi Mokkapati</h5>
              <p className="text-gray-400">
                The work done by Prem and his team is wonderful. I'm so satisfied with the LED board work done by them for my dental clinic. So professional and comfortable people.
              </p>
              <div className="text-orange-500 flex justify-center space-x-1 mt-2">
                {[...Array(4)].map((_, i) => (
                  <i key={i} className="bi bi-star-fill"></i>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex justify-center">
          <div className="md:w-3/4">
            <h3 className="pb-4 mb-4 text-2xl font-semibold border-b text-orange-500">What sets us apart?</h3>

            <article>
              <h2 className="text-3xl text-orange-500 mb-3">Our Expertise in Flex Printing</h2>
              <p className="text-gray-300">
                Flex printing involves premium digital banner printing using high-quality industrial presses. We also offer alternatives such as star flex, which provides a shiny, reflective surface for enhanced visual appeal.
              </p>
              <hr className="my-4" />
              <p className="text-gray-300">
                In today’s competitive world, effective marketing is essential for businesses of all sizes. Flex Printing Services in Miyapur, Hyderabad remain popular for printing images and product descriptions on materials like plastic, paper, banners, hoardings, and packaging, even in this digital age.
              </p>
              <h2 className="text-3xl text-orange-500 mt-4">Signboard Design Excellence</h2>
              <p className="text-gray-300">
                Signboards are more than just tools; they create a strong visual impact that instantly captures attention. Acting as digital front doors, they showcase a brand’s personality, message, and offerings in a compelling way.
              </p>
              <blockquote className="italic text-gray-300 border-l-4 border-orange-500 pl-4">
                With bold, eye-catching designs, our signboards communicate essential information—whether it’s a business name, tagline, or key details—ensuring your brand stands out.
              </blockquote>
              <h3 className="text-3xl text-orange-500 mt-4">Our Achievements</h3>
              <ul className="list-disc list-inside text-gray-300">
                <li>Over 300 positive reviews, reflecting customer satisfaction.</li>
                <li>Recognized with stamps like JD Verified, JD Trusted, and JD Escrow, substantiating our credibility.</li>
                <li>Committed to delivering a positive and seamless customer experience.</li>
              </ul>
              <div className='text-white'>
              <WhyUs/>
              </div>
           
            </article>
          </div>
        </div>
      </section>
    </div>
    </div>
  );
};

export default AboutPage;
