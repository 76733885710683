import React, { useState } from 'react';
import { services } from '../../data/Data';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const [hoveredService, setHoveredService] = useState(null);
  const navigate = useNavigate();

  const handleServiceDetails = (service) => {
    navigate(`/service/${service.id}`); // Navigate to service detail page
  };

  return (
    <div className="bg-black text-orange-500 p-8">
      <h1 className="text-center text-white font-serif text-4xl mb-8">Our Services</h1>

   
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {services.map((service) => (
          <div
            key={service.id}
            className="relative"
            onMouseEnter={() => setHoveredService(service)} 
            onMouseLeave={() => setHoveredService(null)}
          >
            <div
              className="cursor-pointer bg-gray-800 p-6 rounded-lg text-center text-orange-500"
              style={{
                boxShadow: ` 
                  0 2px 4px rgba(50, 50, 50, 0.8),        /* Base shadow for depth */
                  0 4px 8px rgba(255, 165, 0, 0.6),       /* Orange glow layer */
                  0 8px 16px rgba(255, 165, 0, 0.4)       /* Subtle extended glow */
                `,
                background: `linear-gradient(145deg, #333333, #1a1a1a)`, 
                transition: 'transform 0.3s ease-in-out',
              }}
            >
              {service.name}
            </div>

           
            {hoveredService?.id === service.id && (
              <div className="absolute top-0 left-0 w-full bg-black bg-opacity-80 p-4 rounded-lg flex flex-col items-center transition-all duration-500 ease-in-out z-10">
                <div className="flex flex-col sm:flex-row w-[400px] text-sm bg-black bg-opacity-80">
          
                  <div className="flex-1 text-left mr-4">
                    <h2 className="text-2xl text-orange-500 mb-4">{service.name}</h2>
                    <p className="text-white text-sm">{service.info}</p>
                  </div>

          
                  <div className="w-full sm:w-1/3">
                    <img
                      src={service.img}
                      alt={service.name}
                      className="w-full h-auto object-contain"
                    />
                  </div>
                </div>
                <button
                  onClick={() => setHoveredService(null)}
                  className="bg-red-500 px-4 py-2 text-white rounded hover:bg-red-600 transition mt-4"
                >
                  Close
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
