import React from 'react';

const WhyUs = () => {
    const features = [
        { icon: "fa-solid fa-pen-ruler", title: "Unique design", description: "Vibrant colors, variable brightness, and versatile designs to attract people." },
        { icon: "fa-regular fa-money-bill-1", title: "Affordable Prices", description: "I design and develop services for customers of all sizes, specializing in creating stylish, modern websites." },
        { icon: "fa-sharp fa-light fa-gem", title: "Good Quality", description: "I design and develop services for customers of all sizes, specializing in creating stylish, modern websites." },
        { icon: "fa-solid fa-calendar-days", title: "Fast Delivery", description: "I design and develop services for customers of all sizes, specializing in creating stylish, modern websites." },
    ];

    return (
        <section className="py-9" id="services">
            <div className="container mx-auto px-4">
                <div className="mb-12 text-center">
                    <h2 className="text-4xl font-bold text-orange-500 border-l-4 text-center border-red-500 pl-4">Why Us</h2>
                    <p className='text-center mt-8 max-w-3xl mx-auto'>
                        We provide end-to-end branding solutions, from signboards to digital displays. 
                        With innovative designs and durable materials, we bring your brand's vision to life. 
                        Your one-stop shop for impactful, high-quality signage!
                    </p>
                    <p className="mt-4 text-gray-700"></p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-lg p-8 rounded-lg hover:-translate-y-2 transform transition duration-300 relative group"
                        >
                            <div className="w-16 h-16 flex items-center justify-center bg-red-500 text-white text-2xl rounded-full mb-6">
                                <i className={`fa ${feature.icon}`}></i>
                            </div>
                            <h5 className="text-xl font-semibold text-orange-500 group-hover:text-black transition duration-300">
                                {feature.title}
                            </h5>
                            <p className="text-gray-700 group-hover:text-orange-500 mt-2 transition duration-300">
                                {feature.description}
                            </p>
                            <div className="absolute inset-0 bg-black rounded-lg opacity-0 group-hover:opacity-100 transition duration-300 -z-10"></div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WhyUs;
