import React, { useState } from "react";
import { gallery } from "../../data/Data";
import "./gallery.css";  

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (src) => {
    setSelectedImage(src);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="bg-black text-gray-400 p-4">
      {/* Heading Section */}
      <div className="text-orange-500 py-10 text-center">
        <h1 className="text-3xl font-bold font-serif">Image Gallery</h1>
      </div>

      {/* Gallery Section */}
      <div className="container mx-auto mt-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {gallery.map(({ id, cover, category }) => (
            <div key={id} className="gallery-item" onClick={() => handleImageClick(cover)}>
              <img
                src={cover}
                alt={category}
                className="w-72 h-48 object-cover rounded-md transition-transform duration-300 ease-in-out"
              />
              <span className="overlay"></span>
             
              <span className="category-name">{category}</span>
            </div>
          ))}
        </div>
      </div>
      {/* Modal Section */}
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={handleCloseModal}>
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              alt="Selected"
              className="max-w-full max-h-screen rounded-lg"
            />
            <button className="absolute top-2 right-2 bg-white text-black rounded-full p-2" onClick={handleCloseModal}>
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
