import React, { useState, useEffect } from "react";

const Test = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [
    "./images/gallery/onee.jpg",
    "./images/gallery/vinyl2.jpg",
    "./images/gallery/close3.jpg",
    "./images/gallery/steel letter.jpg",
    "./images/gallery/fabric5.jpg",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000); 
    return () => clearInterval(interval);
  }, [activeIndex]);

  const goToNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="relative w-full h-[70vh] overflow-hidden">
      <div
        className="flex w-full h-full transition-transform duration-1000 ease-in-out"
        style={{
          transform: `translateX(-${activeIndex * 100}%)`,
        }}
      >
        {images.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Slide ${index + 1}`}
            className="w-full h-full object-cover rounded-xl flex-shrink-0"
          />
        ))}
      </div>

     
      <button
        onClick={goToPrevious}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white bg-black rounded-full h-14 w-14 shadow-lg hover:bg-gray-800 transition border-2 border-orange-500"
      >
        &lt;
      </button>
      <button
        onClick={goToNext}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white bg-black rounded-full h-14 w-14 shadow-lg hover:bg-gray-800 transition border-2 border-orange-500"
      >
        &gt;
      </button>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
        {images.map((_, index) => (
          <span
            key={index}
            onClick={() => goToSlide(index)}
            className={`block h-2 w-8 cursor-pointer rounded-full transition-all duration-300 ${
              activeIndex === index ? "bg-white" : "bg-white/50"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Test;
