import React, { useState } from "react";
import { Link } from "react-router-dom";

const servicesDropdown = [
  { text: "Acrylic Close LED", id: 1 },
  { text: "Acrylic Open LED", id: 2 },
  { text: "CNc Cutting Acrylic", id: 3 },
  { text: "Acrylic Channel Letters", id: 4 },
  { text: "Acrylic Liquid Letters", id: 5 },
  { text: "Aluminium Standees", id: 6 },
  { text: "Glow Sign Boards", id: 7 },
  { text: "Flute Board", id: 8 },
  { text: "Fabric Board", id: 9 },
  { text: "Acrylic Sandwich", id: 10 },
  { text: "Business Cards", id: 11 },
  { text: "Pamphlets/Brochures", id: 12},
];

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const navItems = [
    { text: "Home", path: "/" },
    { text: "About", path: "/about" },
    { text: "Services", path: "/services" },
    { text: "Contact", path: "/contact" },
  ];

  return (
    <nav className="bg-black bg-opacity-50 fixed top-0 left-0 right-0 z-20 shadow-md w-full">
      <div className="max-w-screen-xl mx-auto px-4 py-2 flex justify-between items-center">

        <a href="#" className="flex items-center text-white">
          <img
            src="./premdigitallogo.png" 
            alt="Logo"
            width="85"
            height="85"
            className="mr-2"
          />
       <span className="text-4xl font-semibold">
  <span className="text-orange-500">p</span>rem
  <span className="text-orange-500">d</span>igital
</span>
        </a>

        <button
          className="text-2xl focus:outline-none md:hidden"
          onClick={toggleMobileMenu}
        >
          <i className="fa-solid fa-bars text-orange-500"></i>
        </button>

        <div className="hidden md:flex space-x-6">
          <a href="/" className="text-white   hover:scale-125 transition-all duration-300">Home</a>
          <a href="/about" className="text-white hover:scale-125  duration-300">About Us</a>
          <div className="relative group">
            <button className="text-white hover:scale-125  duration-300">Services</button>
            <div className="absolute hidden group-hover:block bg-black bg-opacity-75 text-white shadow-md top-full left-0 px-4 py-2 z-50 w-72">
              {servicesDropdown.map((service, index) => (
                <Link
                  key={index}
                  to={`/service/${service.id}`} // Use id for routing
                  className="block hover:bg-orange-500 py-2 px-4 transition-colors duration-300"
                >
                  {service.text}
                </Link>
              ))}
            </div>
          </div>
          <a href="/contact" className="text-white  hover:scale-125  duration-300">Contact Us</a>
          <a href="/gallery" className="text-white  hover:scale-125  duration-300">Gallery</a>
        </div>
      </div>

     
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-gray-900 bg-opacity-50 z-40"
          onClick={toggleMobileMenu}
        >
          <div
            className="fixed top-0 right-0 w-64 h-full bg-white shadow-lg p-6 z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="text-black text-xl mb-6 focus:outline-none"
              onClick={toggleMobileMenu}
            >
              <i className="fa-solid fa-times"></i>
            </button>
            <ul className="space-y-6">
              {navItems.map((item, index) =>
                item.text === "Services" ? (
                  <li key={index} className="relative">
                    <span className="text-black capitalize cursor-pointer">
                      {item.text}
                    </span>
                    <ul className="mt-0 bg-white text-black rounded shadow-md">
                      {servicesDropdown.map((service, idx) => (
                        <li key={idx}>
                          <Link
                            to={`/service/${service.id}`}
                            className="block px-4 py-2 hover:bg-orange-200 transition-colors duration-300"
                            onClick={toggleMobileMenu}
                          >
                            {service.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li key={index}>
                    <Link
                      to={item.path}
                      className="text-black capitalize hover:text-orange-500 transition-colors duration-300"
                      onClick={toggleMobileMenu}
                    >
                      {item.text}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
