import React from 'react';
import { sliderImages } from '../../data/Data';

const Slider = () => {
  return (
    <div className="relative w-11/12 mx-auto h-64 flex items-center overflow-hidden">
      <div className="flex w-[calc(250px*10)] animate-scroll">
        {sliderImages.map((image, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-64 h-48 flex items-center justify-center p-4 overflow-hidden"
          >
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-full object-cover transform transition-transform duration-500 hover:scale-110"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
